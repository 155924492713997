import React from 'react'
import Section from './common/Section'

const Footer = () => {
  return (
    <Section>
        <div className='text-center font-medium text-gray-500 mb-10'>
            @ Akarsh Sharma 
        </div>
    </Section>
  )
}

export default Footer