import React from 'react'
import { FaTwitter, FaFacebook, FaLinkedin, FaArrowDown } from 'react-icons/fa'
import avatar from "../assets/avatar.png"
const Hero = () => {

    const SOCIAL = [
        {
            id: 1,
            link: "https://twitter.com",
            icon: <FaTwitter/>
        }, 
        {
            id: 2,
            link: "https://www.facebook.com/akarsh.sharma.397",
            icon: <FaFacebook/>
        },
        {
            id: 3,
            link: "https://www.linkedin.com/in/akarsh-sharma/",
            icon: <FaLinkedin/>
        }
    ];

    window.addEventListener('scroll', function () {
        const downArrow = document.querySelector(".down-arrow");

        if(this.scrollY >= 90) downArrow.classList.add("hide-down-arrow"); 
        else downArrow.classList.remove("hide-down-arrow");// a css class in index.css that sets display to 'none'
    })

  return (
    <section className='min-h-screen flex flex-col justify-start items-center p-5 text-center'>
        <h2 className='text-5xl text-rose-600 uppercase font-bold'>Akarsh Sharma</h2>
        <h3 py-3 text-2xl>Software Engineer</h3>
        <p className='max-w-xl font-light text-gray-500'>
            <span className='animate-pulse text-4xl'></span>
            
            <h4 className=' text-black dark:text-gray-400 '>
            Hi, my name is Akarsh and I am a graduate from the University of Toronto, St. George Campus specializing in Computer Science. 
            I am a passionate programmer with extensive experience in developing software using Object-Oriented Programming 
            languages such as Java and C# as well as web development technologies such as HTML, JavaScript, and CSS. My 
            experience with .NET allows me to develop robust and scalable applications for different platforms. Moreover, I have 
            working knowledge of SQL and Microsoft SQL Server, which enables me to develop and maintain databases to support 
            web applications. My ability to work with cross functional teams and communicate effectively allows me to deliver 
            high-quality products that meet business objectives. I have a keen eye for detail and I am committed to staying up-to-date 
            with the latest technologies and industry trends to continuously improve my skill set and provide innovative solutions.
            </h4>
            
            <span className='animate-pulse text-4xl'></span>
        </p>

        {/* Social icons */}
        <div className='flex justify-evenly py-5 lg:py-10 text-3xl w-full md:w-1/3' /* makes the icon padding responsive using tailwind*/>  
            MY SOCIALS
            {SOCIAL.map(({id, link, icon}) => (
                <a 
                href={link}
                key={id}
                target='_blank' // opens links in new tab
                rel="noopener noreferrer" // opens links in new tab
                className="cursor-pointer duration-300 hover:text-rose-600">
                    {icon}
                </a>
            ))}
        </div>

        {/* Profile Avatar and Resume download link*/}
        <div>
            <img src={avatar} alt="" 
            className='w-60 h-60 md:w-72 md:h-72 object-cover object-top bg-gradient-to-b from-rose-600 rounded-xl pt-3'/>
            <a href='/akarsh-resume.pdf' download={true} 
            className="flex items-center justify-center mt-10 bg-gradient-to-r from-rose-600
            to-teal-500 text-white py-2 rounded-lg font-black">
                My Resume
            </a> 
        </div>

        {/* Arrow down animation */}
        <div className='mt-10 down-arrow' /* the "down-arrow" here is not a tailwind class. we use the 
        down-arrow to query select and remove the arrow from the DOM when needed */>
            <FaArrowDown className='text-gray-400 text-2xl animate-bounce'/>
        </div>
        
    </section>
  )
}

export default Hero