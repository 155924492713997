import React from 'react'
import { FaTwitter, FaFacebook, FaLinkedin } from 'react-icons/fa';
import Section from './common/Section'
import contact from '../assets/mobile.png'

const Contact = () => {
    const SOCIAL = [
        {
            id: 1,
            link: "https://twitter.com",
            icon: <FaTwitter/>
        }, 
        {
            id: 2,
            link: "https://www.facebook.com/akarsh.sharma.397",
            icon: <FaFacebook/>
        },
        {
            id: 3,
            link: "https://www.linkedin.com/in/akarsh-sharma/",
            icon: <FaLinkedin/>
        }
    ];
  return (
    <Section
    title="Contact Me 📱"
    subtitle="These are the ways to reach out to me. Hope to hear from you soon!">
        <div className='flex flex-col items-center justify-center gap-8 text-center'>
            <div>
                <img src={contact} alt="contact info" className='w-32 h-32'/>
            </div>
            <div>
                <p className='max-w-xs md:max-w-lg font-extralight'>
                    I am open to talk regarding freelancing or full-time opportunities. Feel free
                    to contact me at any time through any of the available options below!
                </p>
            </div>
            <div className='flex w-full items-center justify-evenly text-3xl'>
                {SOCIAL.map(({id, link, icon}) => (
                    <a href={link} target="_blank" rel="noopener noreferrer" className='duration-200
                    ease-in-out hover:text-rose-600'>
                        {icon}
                    </a>
                ))}
            </div>
            {/* botttom form*/}
            <div className='p-8 text-left w-full'>
                <form action='https://getform.io/f/7dcc5a09-4813-4995-98fb-2e3571475f9b'
                method='POST'>
                    <div className='gap-4 w-full'>
                        <div className='flex flex-col'>
                            <label className='capitalize text-sm py-2 font-extralight text-center'>
                                name
                            </label>
                            <input type="text" name="name" className='border-2 rounded-lg p-3 flex
                            focus:outline-none border-gray-400 dark:bg-gray-900' />
                        </div>
                        <div className='flex flex-col'>
                            <label className='capitalize text-sm py-2 font-extralight text-center'>
                                phone
                            </label>
                            <input type="text" name="phone" className='border-2 rounded-lg p-3 flex
                            focus:outline-none border-gray-400 dark:bg-gray-900' />
                        </div>
                        <div className='flex flex-col'>
                            <label className='capitalize text-sm py-2 font-extralight text-center'>
                                email
                            </label>
                            <input type="text" name="email" className='border-2 rounded-lg p-3 flex
                            focus:outline-none border-gray-400 dark:bg-gray-900' />
                        </div>
                        <div className='flex flex-col'>
                            <label className='capitalize text-sm py-2 font-extralight text-center'>
                                message
                            </label>
                            <textarea name='message' rows="10" className='border-2 rounded-lg p-3 flex
                            focus:outline-none border-gray-400 dark:bg-gray-900 resize-none'>
                            </textarea>
                        </div>
                    </div>
                    <div className='flex items-center justify-center'>
                        <button className='my-8 bg-gradient-to-r from-rose-600 to-teal-500 text-white
                        px-6 py-3 font-bold uppercase rounded-md tracking-wider cursor-pointer hover:scale-105'>
                            Send message
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </Section>
  )
}

export default Contact