import React from 'react'
import {MdNightsStay, MdWbSunny} from 'react-icons/md'


const Header = ({darkMode, setDarkMode}) => { 
    // passing in {darkMode, setDarkMode} is object destructuring 
    //but we could have also used props to get access to our darkmode useState component
  return (
    <header className='bg-white dark:bg-gray-900 text-gray-900 dark:text-white'>
      <nav className='flex justify-between items-center p-5'>
        <h1></h1>
        <div onClick={() => setDarkMode(!darkMode)}>
            { // conditional rendering 
                darkMode ?  
                <MdWbSunny className='text-2xl cursor-pointer'/> : 
                <MdNightsStay className='text-2xl cursor-pointer'/>
            }
        </div>
      </nav>
    </header>
  )
}

export default Header