import React from 'react';
import Section from './common/Section';
import { FaGithub, FaExternalLinkSquareAlt } from 'react-icons/fa';
import p1 from "../assets/p1.jpg";
import p2 from "../assets/p2.jpg";
import p3 from "../assets/p3.jpg";
import p4 from "../assets/p4.jpg";
import p5 from "../assets/p5.jpg";
import p6 from "../assets/p6.jpg";


const Portfolio = () => {

    const projects = [
        {
            id: 1, 
            image: p1,
            title: "App 1",
            github: "https://github.com/Akarsh-Sharma/CandleStudioReact",
            demo: "https://nodejs.org/en/"

        }, 
        {
            id: 2, 
            image: p2,
            title: "App 2",
            github: "https://github.com/Akarsh-Sharma/wildlife-frontend",
            demo: "https://nodejs.org/en/"

        }
    ]

  return (
    <Section
    title="Portfolio 📄"
    subtitle="These are some of the projects I have worked on.">
        <div className='grid gap-8 lg:gap-14 lg:grid-col-2'>
            {projects.map(({id, image, title, github, demo}) => (
                <div key={id} className="max-w-lg flex shadow shadow-gray-300 rounded-2xl overflow-hidden">
                    <img src={image} alt={title} className="w-2/3"/>
                    <div className='w-1/3 flex flex-col items-center justify-evenly p-1'>
                        <h2>{title}</h2>
                        <a className='text-2xl cursor-pointer duration-150 hover:scale-150' 
                        href={github} 
                        target="_blank" 
                        rel="noopener noreferrer">
                            <FaGithub/>
                        </a>
                        <a className='text-2xl cursor-pointer duration-150 hover:scale-150' 
                        href={demo} 
                        target="_blank" 
                        rel="noopener noreferrer">
                            <FaExternalLinkSquareAlt/>
                        </a>
                    </div>
                </div>
            ))}
        </div>
    </Section>
    )
}

export default Portfolio